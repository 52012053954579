import {Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText } from '@mui/material';
import {FlightTime, } from '.'

// 
export function OpeningDialog (props) {

    const { departureTime, updateDepartureTime, openingDialogOpen , setOpeningDialogOpen } = props

    const closeDialog = ()=>{ 
      setOpeningDialogOpen(false)
    }
   
    return(
    
        <Dialog className="fpl-submit" open={openingDialogOpen} onClose={closeDialog}>
        <DialogTitle>תוכנית טיסה</DialogTitle>

        <DialogContent >
          <DialogContentText dir="rtl" textAlign="right">
            ישומון זו מאפשר הכנת נתיב טיסה.
          </DialogContentText>
          
          <p></p>

          <DialogContentText dir="rtl" textAlign="right">
            הישומון מבצע בדיקות חוקיות בסיסיות בלבד ולכן האחריות על בדיקת כל הפרמטרים בנתיב ובכללם גם  תאימות לפמ"ת, נוטאמים, ומזג אוויר, חלה על הטייס.
          </DialogContentText>

          <p></p>

          <DialogContentText dir="rtl" textAlign="right">
          בשלב ראשון יש לבחור את שעת ההמראה:
          </DialogContentText>

          <FlightTime departureTime = {departureTime} updateDepartureTime = {updateDepartureTime} />

          <p></p>

          <DialogContentText dir="rtl" textAlign="right">
          כעת, יש לסמן את נתיב הטיסה על המפה
          </DialogContentText>

        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={closeDialog}> המשך </Button>
        </DialogActions>
      </Dialog>
    )    
}

export default OpeningDialog;
