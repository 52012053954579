import { memo} from "react";
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';
// import { Icon } from "leaflet";
import L from 'leaflet';
import {LoadAirstrips,LoadAdditionalAirstrips } from "../utils";
import {AirstripIcon } from ".";


  export function Airstrips(props) {

    const {addToRoute, mapUsed} = props

    var airstripIcons = []
    for (let i = 0; i < 18; i++) {

      airstripIcons.push( L.divIcon({
        iconAnchor: [15, 15],
        className: 'airstrip-icon',
        html: ReactDOMServer.renderToString(<AirstripIcon direction={i}/>)
      }))

      // airstripIcons.push( new Icon({
      //   iconUrl: iconUrl,
      //   iconSize: [25, 25]
      //   }))

    }
  
    // const junctions = LoadJunctions(mapUsed);
    const airstrips = LoadAirstrips(mapUsed);

    const additionalAirstrips = LoadAdditionalAirstrips(mapUsed);

    // Create markers for all the airstrips
    return (
      <>
        {airstrips.map((airstrip) => {
          return(
            <Marker
              key={airstrip.name}
              title={airstrip.name}
              icao={airstrip.icao}
              position={airstrip.latlng}
              icon={airstripIcons[parseInt(airstrip.direction)]}
              eventHandlers={{
                click: (e) => {
                  addToRoute(e.target.getLatLng(),e.target.options.title,e.target.options.icao)
                },
              }}
            >
            </Marker>
          )
        })}

        {additionalAirstrips.map((airstrip) => {
          return(
            <Marker
              key={airstrip.name}
              title={airstrip.name}
              icao={airstrip.icao}
              position={airstrip.latlng}
              icon={airstripIcons[parseInt(airstrip.direction)]}
              bubblingMouseEvents={true}
            >
            </Marker>
          )
        })}

{/* {junctions.map((junction) => {
          return(
            <Marker
              key={junction.key}
              title={junction.name}
              icao={junction.icao}
              position={junction.latlng}
              // icon={airstripIcons[parseInt(airstrip.direction)]}
              // eventHandlers={{
              //   click: (e) => {
              //     addToRoute(e.target.getLatLng(),e.target.options.title,e.target.options.icao)
              //   },
              // }}
            >
            </Marker>
          )
        })}



 */}
      </>
    );
  }

export default memo(Airstrips);
