import {Dialog, DialogActions, DialogContent,IconButton, TextField,DialogContentText} from '@mui/material';
import {useState} from "react";

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';


// 
export function EditPoint (props) {


  const { pointToEdit , editPointOpen , setEditPointOpen, removePoint, changePoint} = props

  const [pointName, setPointName] = useState(pointToEdit.name ? pointToEdit.name : "");

  function ConvertDDToDMS(D){
    return [0|D, '° ', 0|(D=(D<0?-D:D)+1e-4)%1*60, "' ", 0|D*60%1*60, '"'].join('');
  }

/***********************************************/

    const buttonSaveClicked = ()=>{ 
        pointToEdit.name = pointName;
        changePoint(pointToEdit)
        setEditPointOpen(false)
    }

/***********************************************/

    const buttonDeleteClicked = ()=>{ 
        removePoint(pointToEdit.key)
        setEditPointOpen(false)
    }

/***********************************************/

const buttonCancelClicked = ()=>{ 
    setEditPointOpen(false)
}

/***********************************************/
    
    return(
    
      <Dialog 
        // className="fpl-submit" 
        open={editPointOpen} 
        onClose={buttonCancelClicked} 
        >

        {/* <DialogTitle>Edit Point</DialogTitle> */}
        <DialogContent>
            <DialogContentText>Coordinates: </DialogContentText>
            <DialogContentText> 
                {/* {pointToEdit.latlng ? pointToEdit.latlng.lat + " N" : ""}  */}
                {pointToEdit.latlng ? ConvertDDToDMS(pointToEdit.latlng.lat) + " N" : ""} 
            </DialogContentText>
            <DialogContentText> 
                {/* {pointToEdit.latlng ? pointToEdit.latlng.lng + " E"  : ""}  */}
                {pointToEdit.latlng ?ConvertDDToDMS(pointToEdit.latlng.lng) + " E"  : ""} 
            </DialogContentText>
            
            <p></p>

            <TextField id="outlined-basic" 
                label="Name"
                placeholder= {pointToEdit.name}
                defaultValue={pointToEdit.name}
                name = "Name"
                onChange={(event) => {setPointName(event.target.value.trim())}} 
                variant="outlined" />
        </DialogContent>

        <DialogActions>

            <IconButton 
                aria-label="save" 
                color="primary"
                onClick={buttonSaveClicked}
                ><SaveIcon />
            </IconButton>

            <IconButton 
                aria-label="delete" 
                color="primary"
                onClick={buttonDeleteClicked}
                ><DeleteIcon />
            </IconButton>

            <IconButton 
                aria-label="delete" 
                color="primary"
                onClick={buttonCancelClicked}
                ><CancelIcon />
            </IconButton>

        </DialogActions>
      </Dialog>
    )    
}

export default EditPoint;
