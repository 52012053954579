import {LoadReportPoints , LoadAirstrips, LoadBubbles, LoadAdditionalAirstrips,LoadJunctions} from "../utils";
import gju from "geojson-utils";


function findFeature(point,reportPoints,airstrips,bubbles,additionalAirstrips,junctions){

    const distance = 700; // 700m

    const updatePoint = (airWay) => {
        var updatedPoint = {...point};
        updatedPoint.name = airWay.name;
        updatedPoint.icao = airWay.icao;
        if(airWay.latlng){
           updatedPoint.latlng.lat = airWay.latlng[0];
           updatedPoint.latlng.lng = airWay.latlng[1];
        }
        return updatedPoint
    }

    const pinPoint = {
        type: 'Point', 
        coordinates:[point.latlng.lat, point.latlng.lng]
    }
   
    // Check if in the airstrips 
    for (let i = 0; i < airstrips.length; i++) {

        const targetPoint = {
            type: 'Point', 
            coordinates: airstrips[i].latlng
        }

        if( gju.pointDistance(pinPoint,targetPoint) < distance)
            return updatePoint(airstrips[i])
    }

    // Check if in the additional airstrips 
    for (let i = 0; i < additionalAirstrips.length; i++) {

        const targetPoint = {
            type: 'Point', 
            coordinates: additionalAirstrips[i].latlng
        }

        if( gju.pointDistance(pinPoint,targetPoint) < distance)
            return updatePoint(additionalAirstrips[i])
    }
   
    // Check the report points
    for (let i = 0; i < reportPoints.length; i++) {

        const targetPoint = {
            type: 'Point', 
            coordinates: reportPoints[i].latlng
        }

        if( gju.pointDistance(pinPoint,targetPoint) < distance)
            return updatePoint(reportPoints[i])
    }

    // Check the bubbles
    for (let i = 0; i < bubbles.length; i++) {

        const bubble = {
            "type":"Polygon", 
            "coordinates": [bubbles[i].coords]
        }

        if( gju.pointInPolygon(pinPoint,bubble))
            return updatePoint(bubbles[i])
    }

    // Check the junctions points
    for (let i = 0; i < junctions.length; i++) {

        const targetPoint = {
            type: 'Point', 
            coordinates: junctions[i].latlng
        }

        if( gju.pointDistance(pinPoint,targetPoint) < distance){

            point.name = junctions[i].name;
            point.icao = junctions[i].icao;
            return point
        }
    }
    

    // return the original point if not found
    return point;
}

function FindNearestFeatureArray (points,mapUsed,timeCategory = "AllOpen")
{
    const reportPoints = LoadReportPoints(mapUsed,timeCategory)
    const airstrips = LoadAirstrips(mapUsed);
    const additionalAirstrips =  LoadAdditionalAirstrips(mapUsed, timeCategory);
    const junctions =  LoadJunctions(mapUsed, timeCategory);
    const bubbles =  (mapUsed === "lsa") ? LoadBubbles(mapUsed, timeCategory): [];
    

    var updatedArray = [];
    
    points.forEach(point => {
        var featuredPoint = findFeature(point,reportPoints,airstrips,bubbles,additionalAirstrips,junctions)
        updatedArray.push(featuredPoint)
    });

    return updatedArray;
}

function FindNearestFeature (point,mapUsed,timeCategory){

    const reportPoints = LoadReportPoints(mapUsed,timeCategory)
    const airstrips = LoadAirstrips(mapUsed);
    const additionalAirstrips =  LoadAdditionalAirstrips(mapUsed, timeCategory);
    const junctions =  LoadJunctions(mapUsed, timeCategory);
    const bubbles =  (mapUsed === "lsa") ? LoadBubbles(mapUsed, timeCategory): [];

    return findFeature(point,reportPoints,airstrips,bubbles,additionalAirstrips,junctions)
}

export  {FindNearestFeature,FindNearestFeatureArray};
