
    export function ExportGPX(route)
    {
      const routeName = route[0].name + "-" + route[route.length - 1].name;

      var  gpxTxt = '<?xml version="1.0" encoding="utf-8"?>\n' +
                    '<gpx\n' + 
                    ' xmlns="http://www.topografix.com/GPX/1/1"\n' + 
                    ' creator="ifl.flight-maps.com">\n' +
                    ' version="1.1"\n' + 
                    ' xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                    ' xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd"\n' + 
                    "  <rte>\n" + 
                    "    <name>" + routeName + "</name>\n" +
                    "    <number>1</number>\n" 

            route.forEach(wpt => {
        gpxTxt += '    <rtept lat="' + wpt.latlng.lat + '" lon="' + wpt.latlng.lng + '">\n' +
                  "      <ele>0.000</ele>\n" +
                  '      <name>' + wpt.name + '</name>\n' +
                  '      <sym>' + wpt.icao + '</sym>\n' +
                  '    </rtept>\n'

      });                    

      gpxTxt += '  </rte>\n' + 
                '</gpx>\n'

      const gpxFileName = routeName + ".gpx"

      var pom = document.createElement('a');
      var bb = new Blob([gpxTxt], {type: 'application/gpx'});
      pom.setAttribute('href', window.URL.createObjectURL(bb));
      pom.setAttribute('download', gpxFileName);
      pom.dataset.downloadurl = ['application/gpx', pom.download, pom.href].join(':');
      pom.draggable = true; 
      pom.classList.add('dragout');
      pom.click();

    }

    export default ExportGPX;
