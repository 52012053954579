import {FindNearestFeature, updateGeoData} from ".";

export async function LoadUrlParams (routeStr,setPlanedRoute,flightMap, mapToUse , timeCategory) {

    var maxLat = -180;
    var minLat = 180;
    var maxLng = -180;
    var minLng = 180

    var route = []

    async function handlePoint(point){
        if(point.length === 15 && point.indexOf(':') === 7){
            const latlng = {
                lat: parseFloat(point.slice(0,7)),
                lng: parseFloat(point.slice(8))
            }

            // Calculate the bounds
            maxLat = latlng.lat > maxLat ? latlng.lat : maxLat;
            minLat = latlng.lat < minLat ? latlng.lat : minLat;
            maxLng = latlng.lng > maxLng ? latlng.lng : maxLng;
            minLng = latlng.lng < minLng ? latlng.lng : minLng;

            const newPoint = {
                latlng: latlng,
                name: "wp",
                icao: "icao",
                key: Math.random() * 1000
            }

            const updatedPoint = FindNearestFeature(newPoint,mapToUse, "AllOpen")

            return await updateGeoData(updatedPoint);
        }
    }

    const LineCoords = routeStr.split("-");

    if(LineCoords.length){
        for (let i = 0; i < LineCoords.length; i++) {
            await handlePoint(LineCoords[i]).then((newPoint) => {route.push(newPoint)});
        }
    }

    setPlanedRoute(route)

    const bounds = [[minLat,minLng],[maxLat,maxLng]]
    flightMap.fitBounds(bounds)
}

export default LoadUrlParams;