import { useState, useCallback, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import {RenderRoute,OpeningDialog,ActionMenu,ChangeDepartureTime, UndoButton} from '.';
import Airstrips from './Airstrips';
import {useLocalStorage,GetTimeCategory,CalcBestRoute,CalcRouteLength,getTimeCategoryExceptions,updateAllGeoData} from '../utils/';
// import { BrowserRouter as Router } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
// import ReactGA from 'react-ga';
import Control from 'react-leaflet-custom-control';
import { Box } from '@mui/material';
import { maps } from "../data";


export function Main() {

  const [planedRoute, setMyPlanedRoute] = useState([]);
  const [routeLength, setRouteLength] = useState(0);
  const [openingDialogOpen, setOpeningDialogOpen] = useState(false);
  const [timeDialogOpen, setTimeDialogOpen] = useState(false);
  const [departureTime, setDepartureTime] = useState(new Date());
  const [timeCategory, setTimeCategory] = useState('AllOpen');
  const [editPointOpen, setEditPointOpen] = useState(false);


  // const [autoRoute, setAutoRoute] = useLocalStorage('autoRoute', true);
  const [mapUsed, setMapUsed] = useLocalStorage('mapUsed', 'lsa');
  const [initialCenter, setInitialCenter] = useLocalStorage('initialCenter',[32.2223, 34.8857]);
  const [enableAllAirways, setEnableAllAirways] = useLocalStorage('enableAllAirways','disable');
  const [timeCategoryExceptions, setTimeCategoryExceptions] = useLocalStorage('timeCategoryExceptions',[]);

  /***********************************************/

  const planedRouteRef = useRef(planedRoute);
  useEffect(() => {
    planedRouteRef.current = planedRoute;
  }, [planedRoute]);

  // const autoRouteRef = useRef(autoRoute);
  // useEffect(() => {
  //   autoRouteRef.current = autoRoute;
  // }, [autoRoute]);


  const mapUsedRef = useRef(mapUsed);
  useEffect(() => {
    mapUsedRef.current = mapUsed;
  }, [mapUsed]);

  const timeCategoryRef = useRef(timeCategory);
  useEffect(() => {
    timeCategoryRef.current = timeCategory;
  }, [timeCategory]);

  const editPointOpenRef = useRef(editPointOpen);
  useEffect(() => {
    editPointOpenRef.current = editPointOpen;
  }, [editPointOpen]);

  /***********************************************/
  // handle what happens on key press
  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Backspace' && !editPointOpenRef.current ) {
      removeLastPointInRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
  /***********************************************/

  const createNewPoint = (latlng, name, icao) => {
    return {
      latlng: { lat: latlng.lat, lng: latlng.lng },
      name: name ? name : 'wp',
      icao: icao ? icao : 'icao',
      key: Math.random() * 1000,
    };
  };

  /***********************************************/

  // append a routing point to the route
  const appendToRoute = (latlng, name, icao, doRoute = false) => {
    const newPoint = createNewPoint(latlng, name, icao);

    const newRoute = [...planedRoute, newPoint];

    if (doRoute ) {
      CalcBestRoute(mapUsed, timeCategory, newRoute, setPlanedRoute, false);
    } else {
      setPlanedRoute(newRoute);
    }

    setInitialCenter([latlng.lat, latlng.lng]);
  };

  /***********************************************/
  // Add a routing point callback
  const addToRoute = useCallback(
    (latlng, name, icao) => {
      const newPoint = createNewPoint(latlng, name, icao);

      const newRoute = [...planedRouteRef.current, newPoint];

      // if (autoRouteRef.current) {
        CalcBestRoute(
          mapUsedRef.current,
          timeCategoryRef.current,
          newRoute,
          setPlanedRoute,
          false
        );
      // } else {
      //   setPlanedRoute((planedRoute) => [...planedRoute, newPoint]);
      // }

      setInitialCenter([latlng.lat, latlng.lng]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[planedRouteRef]);

  /***********************************************/

  // Change a point parameters
  const changePoint = (point) => {

    const index = planedRoute.findIndex((o) => o.key === point.key);
    if (index > -1) {

      point.key = Math.random() * 1000;

      let pointsArray = [...planedRoute];
      pointsArray[index] = point;

      setPlanedRoute(pointsArray);
    }
  };

  /***********************************************/

  // Insert a point at a specific index
  const insertPoint = (point, index) => {
    if (index > -1) {
      let pointsArray = [...planedRoute];
      pointsArray.splice(index, 0, point);
      setPlanedRoute(pointsArray);
    }
  };

  /***********************************************/
  // remove a point from the route
  const removePoint = (key) => {
    const ind = planedRoute.findIndex((o) => o.key === key);
    if (ind > -1) {
      const newRoute = planedRoute.filter((item, index) => {
        return index !== ind;
      });
      
      setPlanedRoute(newRoute);
    }
  };

  /***********************************************/

  // Remove the last point from the route
  const removeLastPointInRoute = () => {

    var route = [...planedRouteRef.current];

    var length = route.length;

    if (length) {
      setPlanedRoute(route.slice(0, -1));
    }
  };

  /***********************************************/

  const removeLastPointInRouteEvent = (e) => {
    e.stopPropagation()
    e.preventDefault()
    removeLastPointInRoute();
  }

    // /***********************************************/

    async function setPlanedRoute (pointsArray) {

      setMyPlanedRoute(pointsArray);
      updateAllGeoData(pointsArray,setMyPlanedRoute);
    }

    /***********************************************/
    const UpdateTimeCategory = (depTime,enableAll) => {

      var tCategory = GetTimeCategory(depTime, timeCategoryExceptions);
      // setTimeRule(tCategory);
 
      setTimeCategory(enableAll === 'enable' ? 'AllOpen' : tCategory)
    };
    /***********************************************/
    const UpdateEnableAllAirways = (status) => {
  
      setEnableAllAirways(status);
      UpdateTimeCategory (departureTime,status);
    };
    /***********************************************/
  
    // update the departure time
    function updateDepartureTime(newTIme) {
      newTIme.setSeconds(0,0);
      setDepartureTime(newTIme);
      UpdateTimeCategory (newTIme,enableAllAirways);
    }

  /***********************************************/

  // get the map to use and the departure time from the command line
  /* eslint-disable no-unused-vars */
  const [searchParams, setSearchParams] = useSearchParams();
  /* eslint-enable no-unused-vars */

  useEffect(() => 
  {
    // fetchTimeCategoryExceptions(setTimeCategoryExceptions);

    const  mapToUse = searchParams.get("map") ;
  
    const ind = maps.findIndex (o => o.key === mapToUse);  
    if(ind > -1){
      setMapUsed(mapToUse)
      setOpeningDialogOpen(false);
    }

    const depTime = searchParams.get('dep');
    if (depTime) {
      updateDepartureTime(new Date(parseInt(depTime)));
    } else {
      var defaultTime = new Date();
      defaultTime.setMinutes(Math.ceil(defaultTime.getMinutes() / 5) * 5 + 75);
      updateDepartureTime(defaultTime);
    }
   
    getTimeCategoryExceptions(function (exceptions){
      if(exceptions){
        var tCategory = GetTimeCategory(departureTime, exceptions);
        setTimeCategoryExceptions(exceptions)
        setTimeCategory(tCategory)
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 
  

  useEffect(() => {
    setRouteLength(CalcRouteLength(planedRoute));
  }, [planedRoute]);


/***********************************************/

// Change map callback
const changeMap = (map) =>{
  if(mapUsed !== map){
    setMapUsed(map)
    // setPlanedRoute([]);
  }
}

/***********************************************/

const { BaseLayer } = LayersControl;

/***********************************************/

  return (
    <>
      <MapContainer
        center={initialCenter}
        zoom={11}
        attributionControl={false}
        scrollWheelZoom={true}
        tapHold={false}
        doubleClickZoom={false}
        maxBounds={[
          [33.8, 33.55],
          [29, 36.5],
        ]}
      >

        <LayersControl position="topleft" >
          {maps.map((map) => 
              <BaseLayer checked = {mapUsed === map.key} name={map.name} key={map.key}>
              <TileLayer 
                  minZoom={map.minZoom}
                  // maxZoom={map.maxZoom}
                  maxZoom={25}
                  maxNativeZoom={map.maxZoom}
                  url={map.url}
                  // attribution={map.attribution} 
                  eventHandlers={{add: (e) => {changeMap(map.key)}}}
              />
              </BaseLayer>
          )}
        </LayersControl>

        <OpeningDialog
          departureTime={departureTime}
          updateDepartureTime={updateDepartureTime}
          openingDialogOpen={openingDialogOpen}
          setOpeningDialogOpen={setOpeningDialogOpen}
        />


        <ChangeDepartureTime
          departureTime={departureTime}
          updateDepartureTime={updateDepartureTime}
          timeDialogOpen={timeDialogOpen}
          setTimeDialogOpen={setTimeDialogOpen}
        />

        <RenderRoute
          route={planedRoute}
          appendToRoute={appendToRoute}
          setPlanedRoute={setPlanedRoute}
          changePoint={changePoint}
          insertPoint={insertPoint}
          removePoint={removePoint}
          editPointOpen={editPointOpen}
          setEditPointOpen={setEditPointOpen}
          mapUsed={mapUsed}
          timeCategory={timeCategory}
          enableAllAirways={enableAllAirways}
        />


        <Airstrips addToRoute={addToRoute} mapUsed={mapUsed} />

        <Control position='topright'>
        <ActionMenu
          planedRoute={planedRoute}
          setPlanedRoute={setPlanedRoute}
          mapUsed={mapUsed}
          departureTime={departureTime}
          updateDepartureTime={updateDepartureTime}
          enableAllAirways={enableAllAirways}
          UpdateEnableAllAirways={UpdateEnableAllAirways}
          setTimeDialogOpen = {setTimeDialogOpen}
        />
      </Control>



      <Control position='bottomleft'>
        <UndoButton 
          removeLastPointInRouteEvent = {removeLastPointInRouteEvent}   
          undoDisabled = {planedRoute.length === 0 ||  timeDialogOpen}
        />
      </Control>

      {/* <RouteList waypointRoute={waypointRoute} /> */}

      <Control position='bottomright'>
        <Box 
          dir = "rtl"
          style={{marginBottom: "-10px", color: 'blue',  zIndex:1}}
          backgroundColor = '#eee'
          sx={{ width: planedRoute.length > 1 ? 70 : 0, height: 18, }}
        >
        <span> &nbsp; {Math.round(routeLength)} מייל ימי </span>
        </Box>
      </Control>

      </MapContainer>

      {/* {planedRoute.length  > 1 ?
      <Control position='bottomright'>
        <Box 
          dir = "rtl"
          style={{marginBottom: "-10px", color: 'blue',  zIndex:1}}
          backgroundColor = '#eee'
          sx={{ width: 70, height: 18, }}
        >
        <span> &nbsp; {Math.round(routeLength)} מייל ימי </span>
        </Box>
      </Control> : null}       */}

    </>
  );
}

export default Main;
