import * as React from "react"

export function AirstripIcon(props) {

  var runwayDirection = parseInt(props.direction) * 10;
  var transform= "rotate(" + runwayDirection + " 12 -12) translate(0 0) scale(0.0375,-0.0375)"
  // var transform= "rotate(" + runwayDirection + " 12 -12) translate(0 0) scale(0.03,-0.03)"

  return (

    <svg 
      version="1.0" 
      xmlns="http://www.w3.org/2000/svg"
      width="24.000000pt" 
      height="24.000000pt" 
      viewBox="0 -24 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet">

      <g 
        transform={transform}
        fill="#0000ff" 
        stroke="none"
      >
        <path d="M290 315 l0 -295 35 0 35 0 0 295 0 295 -35 0 -35 0 0 -295z"/>
        <path d="M225 523 c-80 -42 -126 -118 -126 -207 0 -92 44 -166 123 -207 51 -26 58 -24 58 16 0 28 -5 37 -22 44 -51 20 -90 83 -90 147 0 55 26 101 75 133 30 20 37 30 37 58 0 37 -10 40 -55 16z"/>
        <path d="M370 503 c0 -32 5 -41 30 -55 43 -25 70 -74 70 -131 0 -27 -5 -58 -11 -70 -17 -32 -61 -77 -76 -77 -9 0 -13 -14 -13 -40 0 -22 2 -40 5 -40 16 0 84 42 107 67 78 82 78 234 0 316 -23 25 -91 67 -107 67 -3 0 -5 -17 -5 -37z"/>
      </g>
    </svg>
  )
}

export default AirstripIcon
