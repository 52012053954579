export async function fetchTimeCategoryExceptions(setTimeCategoryExceptions){

    try {
        let  timeCategoryObj = await fetch("data/timeCategory.json",{headers : { 'Content-Type': 'application/json','Accept': 'application/json'}});

        if(timeCategoryObj && timeCategoryObj.status === 200){
            var timeCategoryExceptions = await timeCategoryObj.json()
            setTimeCategoryExceptions(timeCategoryExceptions)
        }
    } catch (error) {
        console.log("Fetch exception: ",error);
    }
}


export function GetTimeCategory(departureTime, timeCategoryExceptions){


    var timeCategory = "WeekDay"
    var dayOfWeek = departureTime.getDay()
    var departureHour = departureTime.getHours();

    if(dayOfWeek === 6){
        timeCategory = "AllOpen"
    } else {

        if (dayOfWeek === 5){
        
            if(departureHour < 12){
                timeCategory = "OpenOnly6"
            } else if(departureHour < 14){
                timeCategory = "OpenOnly12"
            } else {
                timeCategory = "AllOpen"
            }
        }

        if(timeCategoryExceptions && Array.isArray(timeCategoryExceptions) ){

            // console.log('timeCategoryExceptions',timeCategoryExceptions);

            var isoDate = departureTime.toJSON().slice(0,10)
            var dayFound = timeCategoryExceptions.find(o => o.date.slice(0,10) === isoDate)
    
            if(dayFound){
                if(dayFound.status === "AllOpen"){
                    timeCategory = "AllOpen"
                } else if(dayFound.status === "OpenAllAt14"){
                    if(departureHour >= 14){
                        timeCategory = "AllOpen"
                    }
                } else if(dayFound.status === "OpenAllAt12"){
                    if(departureHour >= 12){
                        timeCategory = "AllOpen"
                    }
                } 
            }
        }

        // if(timeCategoryExceptions && timeCategoryExceptions.dates ){

        //     var isoDate = departureTime.toJSON().slice(0,10)
        //     var dayFound = timeCategoryExceptions.dates.find(o => o.date.slice(0,10) === isoDate)

        //     if(dayFound){
        //         if(dayFound.status === "AllOpen"){
        //             timeCategory = "AllOpen"
        //         } else if(dayFound.status === "OpenAllAt14"){
        //             if(departureHour >= 14){
        //                 timeCategory = "AllOpen"
        //             }
        //         } else if(dayFound.status === "OpenAllAt12"){
        //             if(departureHour >= 12){
        //                 timeCategory = "AllOpen"
        //             }
        //         }
        //     }
        // }
    }

    return timeCategory
}


