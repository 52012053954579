// import {FindNearestFeatureArray} from '.';
const gpxParser = require("./GPXParser");


export function ImportGPX(setPlanedRoute, flightMap)
{
    var fileReader = null;

    const handleGpxFileRead = (e) => {

        const content = fileReader.result;
        var gpx = new gpxParser(); //Create gpxParser Object
        gpx.parse(content); //parse gpx file from string data

        var gpxRoute = null

        if (gpx){
            if(gpx.routes &&  gpx.routes.length && gpx.routes[0].points){
                gpxRoute = gpx.routes[0].points
            } else if(gpx.tracks && gpx.tracks.length && gpx.tracks[0].points){
                gpxRoute = gpx.tracks[0].points
            }
        }
        
        if(gpxRoute === null){
            alert("Cannot process gpx file")
            return
        }

        var pointSegment = 1

        if (gpxRoute.length > 100){
            pointSegment = Math.ceil( gpxRoute.length / 200)
        }

        var maxLat = -180;
        var minLat = 180;
        var maxLng = -180;
        var minLng = 180
    
        var fileRoute = []

        const insertPoint = (point) => {

            const filePoint = {
                latlng: { lat: point.lat, lng: point.lon },
                name: point.name ? point.name : "wp",
                icao:  point.sym ? point.sym : "icao",
                key: Math.random() * 1000,
            };
            

            // Calculate the bounds
            maxLat = point.lat > maxLat ? point.lat : maxLat;
            minLat = point.lat < minLat ? point.lat : minLat;
            maxLng = point.lon > maxLng ? point.lon : maxLng;
            minLng = point.lon < minLng ? point.lon : minLng;

            fileRoute.push((filePoint))
        }

        var lastI = 0

        for (let i = 0; i < gpxRoute.length; i+= pointSegment) {
            lastI = i;
            insertPoint(gpxRoute[i]);
        } 

        if(lastI !== gpxRoute.length - 1){
            insertPoint(gpxRoute[gpxRoute.length - 1]);
        }

                
        // const newRoute = FindNearestFeatureArray(fileRoute,'lsa','AllOpen')
        setPlanedRoute(fileRoute)

        const bounds = [[minLat,minLng],[maxLat,maxLng]]
        flightMap.fitBounds(bounds)
      }
      
      const handleGpxFileChosen = (file) =>{
        fileReader = new FileReader();
        fileReader.onloadend = handleGpxFileRead;
        fileReader.readAsText(file)
      }
      
      function buildFileSelector(){
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', '.gpx');
        fileSelector.onchange=e => handleGpxFileChosen(e.target.files[0]);
        return fileSelector;
      }
      
      const fileSelector = buildFileSelector();

      fileSelector.click();
}
