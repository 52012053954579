import {useState, useRef, useEffect} from "react";
import {WindyIcon} from ".";
import {IconButton, 
        ListItemIcon, 
        ListItemText, 
        MenuItem, 
        Menu, 
        Divider, 
        Radio, 
        RadioGroup,
        FormControl, 
        FormControlLabel, 
        Typography, 
        } from '@mui/material';
import { useMap} from 'react-leaflet';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuIcon from '@mui/icons-material/Menu';
import { BrowserView} from 'react-device-detect';
import {GetRouteUrl,ExportGPX,ImportGPX, GetWindyUrl} from "../utils";
import L from 'leaflet';

  export function ActionMenu (props) {

    const {planedRoute, setPlanedRoute, 
           mapUsed, 
           departureTime, 
           enableAllAirways, UpdateEnableAllAirways,
           setTimeDialogOpen,
          } = props

    const [anchorElMenu, setAnchorElMenu] = useState(null);

    const flightMap = useMap()

    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            L.DomEvent.disableClickPropagation(ref.current);
        }
    });
    
/***********************************************/
    const handleOpenMenu = (e) => {

      setAnchorElMenu(e.currentTarget);
    };

/***********************************************/
     const handleCloseMenu = () => {
      setAnchorElMenu(null);
    };
      
/***********************************************/
    const makeAction = (action) => (e) => {
      handleCloseMenu()
      action(e)
    }

/***********************************************/

    const  sendWhatsapp = (whatsAppText) => {
      var url = "https://wa.me/?text=" + whatsAppText;
      window.open(url, '_blank');
    }

/***********************************************/
    const ShareFlightPlan = () => {

      const routeUrl = GetRouteUrl(mapUsed,planedRoute,departureTime)

      const whatsAppText = planedRoute[0].name + "-" + planedRoute[planedRoute.length - 1].name + '%0D%0A' + routeUrl;

      sendWhatsapp(whatsAppText)
    }

/***********************************************/
const exportGPX = () => {
  ExportGPX(planedRoute);
};

/***********************************************/
const importGPX = () => {
  ImportGPX(setPlanedRoute,flightMap);
};

/***********************************************/
const enableAirwaysChange = (event) => {
  UpdateEnableAllAirways(event.target.value);
};

/***********************************************/
// const autoRouteChange = (event) => {
//   UpdateAutoRoute(event.target.checked);
// };

/***********************************************/
const openTimeDialog = () => {
  setTimeDialogOpen(true);
};

/***********************************************/
// const navigateRoutes = () => {
//   CalcBestRoute(mapUsed, timeCategory, planedRoute, setPlanedRoute,true)
// }

/***********************************************/
const callWindy = () => {
  const windyUrl = GetWindyUrl(planedRoute, departureTime)
  console.log(windyUrl);
  window.open(windyUrl, '_blank');
}

/***********************************************/
    // empty the route array
const clearRoute = () => {
  setPlanedRoute([]);
}


/***********************************************/

function MItem (props){
  const {text, icon, action} = props
  return(
    <MenuItem onClick={makeAction(action)} disabled = {props.disabled}>
        <ListItemIcon size="small"> 
          {icon} 
        </ListItemIcon>
        <ListItemText primary={text}  primaryTypographyProps={{fontSize: '14px', textAlign: "right"}}/>
    </MenuItem>
  )
}
/***********************************************/

// function EnableAutoRouteSwitch(props){
  
//   return(
//     <FormControlLabel
//         label= {<Typography sx={{fontSize: 14 }}>השלם נתיב אוטומטית</Typography>}
//         // sx={{'& .MuiSvgIcon-root': {fontSize: 16,},}}
//         control={ <Switch 
//                   onChange={autoRouteChange} 
//                   checked={autoRoute}
//                   size="small"
//                 />}
//     />
//   )
// }

/***********************************************/


function EnableAirwaysRadio(props){

  return (

    <>
    <FormControl>
      <RadioGroup
        aria-labelledby='Enable-all-airways'
        name='Enable-all-airways'
        value={enableAllAirways}
        onChange={enableAirwaysChange}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
      >
        <FormControlLabel
          value='disable'
          control={<Radio />}
          label={
            <Typography sx={{ fontSize: 14 }}>
              ניתוב ע"פ זמן ההמראה
            </Typography>
          }
        />
        <FormControlLabel
          value='enable'
          control={<Radio />}
          label={
            <Typography sx={{ fontSize: 14 }}>
              ניתוב בכל הנתיבים והבועות
            </Typography>
          }
        />
      </RadioGroup>
    </FormControl>
    </>

  );
}

/***********************************************/
    
    return (
      <>
      <div ref={ref}>
      {/* <Tooltip title="Menu" arrow > */}
        <span>
          <IconButton 
            // size="small"
            sx={{ mr:1, bgcolor: '#1976d2', color:'#fff', '&:hover': {backgroundColor: '#1565c0',color: '#fff'}}}
            variant="contained"
            aria-label="menu" 
            onClick={handleOpenMenu} 
            >
            <MenuIcon />
          </IconButton>
        </span>
      {/* </Tooltip> */}
      </div>
      <Menu 
        dir="RTL" 
        sx={{textAlign: "right",  mt: '45px'}}
        anchorEl={anchorElMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ style: { width: 280,},}} 
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
        >
        {/* <EnableAutoRouteSwitch/> */}
        <p/>
        <EnableAirwaysRadio />
        <Divider />
        <MItem text = "מחק נתיב" icon = {<DeleteIcon/>} action = {clearRoute}  disabled={planedRoute.length < 1} />
        <MItem text = "קבע זמן המראה" icon = {<AccessTimeIcon/>} action = {openTimeDialog} />
        <Divider />
        <MItem text = "טען קובץ ניווט" icon = {<FileUploadIcon/>} action = {importGPX} />
        <MItem text = "שמור לקובץ ניווט" icon = {<FileDownloadIcon/>} action = {exportGPX}  disabled={planedRoute.length < 2} />
        <MItem text = "שתף" icon = {<WhatsAppIcon/>} action = {ShareFlightPlan}  disabled={planedRoute.length < 2} />
        <BrowserView>
        <MItem text = "מזג האויר בנתיב" icon = {<WindyIcon/>} action = {callWindy}  disabled={planedRoute.length < 2} />
        </BrowserView>
      </Menu>
      </>

      
    );
  }

  export default ActionMenu;
