import {LoadReportPoints, LoadAirstrips, LoadBubblesPoints, isAirport, dbPointToRoutePoint} from ".";
import {weekendRoutes } from "../data";
import gju from "geojson-utils";
import dijkstra from "./dijkstra"

var find_path = dijkstra.find_path;

const findAirwayEdges = (airway) => {

    if(airway.routePoints.length){
        var edges = {}
        airway.routePoints.forEach(routePoint => {
            edges[routePoint.icao] = routePoint.distance
        })
        return edges;
    } else{
        return null
    }
}



// Remove the weekend routes which are between weekday points
const filterWeekendRoutes = (points) => {

    weekendRoutes.forEach(route => {
        const ind = points.findIndex((o) => o.icao === route[0]);
        if (ind > -1 ) {
            if(points[ind].routePoints){
                const rp = points[ind].routePoints.findIndex((a) => a.icao === route[1]);
                if(rp > -1) {
                    points[ind].routePoints.splice(rp, 1)
                }
            }
        }
    });

    return points;
}


export function CalcBestRoute(mapUsed,timeCategory, planedRoute, setPlanedRoute, AllRoute){

    if(planedRoute.length < 2){
        setPlanedRoute(planedRoute)
        return
    }

    var newRoute = []

    var reportPoints = LoadReportPoints(mapUsed,timeCategory)
    const airstrips = LoadAirstrips(mapUsed);
    const bubblePoints = LoadBubblesPoints(mapUsed,timeCategory);

    if(timeCategory ===  "WeekDay"){
        reportPoints = filterWeekendRoutes(reportPoints)
    }

    function findAirstripEdges(icao){

        if(!isAirport(icao))
            return null

        const airstrip = airstrips.find(point => point.icao === icao)
    
        if(airstrip){
            return findAirwayEdges(airstrip)
        } else {
            return null
        }
    }

    const navPoints = [...reportPoints,...bubblePoints]

    const findNavPoint = (icao) => { return navPoints.find(point => point.icao === icao)}

    // Prepare the report points graph
    var navPointsGraph = {}
    navPoints.forEach(point => {
        const edges = findAirwayEdges(point)
        if(edges){
            navPointsGraph[point.icao] = edges
        }
    });




    function findBestRoute (icaoStart, icaoEnd){

        var newSegment = []

        var routeGraph = {...navPointsGraph}

    
        // add to the graph in case of airport
        var startEdges = findAirstripEdges(icaoStart)
        if(startEdges){
            routeGraph[icaoStart] = startEdges;
        }

        // add to the graph in case of airport
        var endEdges = findAirstripEdges(icaoEnd)
        if(endEdges){
            routeGraph[icaoEnd] = endEdges;
        }

        // In case of bubbles: adjust the distance according to the real target and not the bubble central point
        const adjustEdgePointBubble = (icao,start) => {

            // If it is a bubble
            var bubblePoint = bubblePoints.find((p) => p.icao === icao)
            if(bubblePoint){
                var point = planedRoute.find((p) => icao === p.icao)
                if(point){
                    if(bubblePoint.routePoints.length){
                        bubblePoint.routePoints.forEach(routePoint => {

                            var destLat = 0;
                            var destLng = 0;

                            // First try to find the route point in the route, in case it's a bubble too
                            var destIcao = routePoint.icao;
                            var destPoint = planedRoute.find((p) => destIcao === p.icao)

                            if(destPoint){
                                destLat = destPoint.latlng.lat 
                                destLng = destPoint.latlng.lng 
                            } else {
                                var navP = navPoints.find((g) => g.icao === destIcao)
                                if(navP){
                                    destLat = navP.latlng[0]
                                    destLng = navP.latlng[1]
                                }
                            }

                            if(destLat > 0 && destLng > 0)
                            {
                                var newDistance =  Math.floor(gju.pointDistance(
                                    {type: 'Point',coordinates:[destLng,destLat]},
                                    {type: 'Point',coordinates:[point.latlng.lng, point.latlng.lat]}) / 185);

                                if(routeGraph[destIcao]){
                                    if(start){
                                        // if start - correct in the start bubble
                                        routeGraph[icao][destIcao] = newDistance;
                                    }else {
                                        // If end - correct in the points which point to the bubble
                                        routeGraph[destIcao][icao] = newDistance;
                                    }
                                }
                            }
                        })
                    }
                }
            }
        }
   
        adjustEdgePointBubble(icaoStart,true)
        adjustEdgePointBubble(icaoEnd,false)

        if((icaoStart !== icaoEnd) && (startEdges || findNavPoint(icaoStart)) && (endEdges || findNavPoint(icaoEnd))){

            var path = find_path(routeGraph, icaoStart, icaoEnd);

            if(path.length === 1 ){
                alert("לא נמצא נתיב חוקי")
                newSegment = null
            } else {

                if(path.length > 2){
                    for (let j = 1; j < path.length -1; j++) {

                        const icao =  path[j]
                        const rPoint = findNavPoint(icao)

                        if(rPoint){
                            newSegment.push(dbPointToRoutePoint(rPoint))
                        }
                    }
                }
            }
        }

        return newSegment
    }

    newRoute.push(planedRoute[0])

    if(AllRoute){

        for (let i = 0; i < planedRoute.length - 1; i++) {

            const icaoStart = planedRoute[i].icao
            const icaoEnd = planedRoute[i+1].icao

            newRoute = [...newRoute,...findBestRoute(icaoStart,icaoEnd),planedRoute[i+ 1]]

        }
    } else {

        // calculate only for the last 2 points

        const len = planedRoute.length;

        if(len > 1){
            const icaoStart = planedRoute[len -2].icao
            const icaoEnd   = planedRoute[len - 1].icao
            const newWay = findBestRoute(icaoStart,icaoEnd)

            if(newWay){
                newRoute = [...planedRoute.slice(0, len - 1), ...newWay, planedRoute[len -1]]
            } else {
                newRoute = [...planedRoute.slice(0, len - 1)]
            }

            // newRoute = [...planedRoute.slice(0, len - 1), ...findBestRoute(icaoStart,icaoEnd), planedRoute[len -1]]
        } else {
            newRoute = [...planedRoute]
        }

    }

    setPlanedRoute(newRoute)

}


export function addAllAirways(mapUsed,setPlanedRoute){
    const reportPoints = LoadReportPoints(mapUsed,"AllOpen")
    const bubblePoints = LoadBubblesPoints(mapUsed,"AllOpen");

    var newRoute = []

    reportPoints.forEach((point) => {
        newRoute.push(dbPointToRoutePoint(point))
    })

    bubblePoints.forEach((point) => {
        newRoute.push(dbPointToRoutePoint(point))
    })

    setPlanedRoute(newRoute)
}

