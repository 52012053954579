import gju from "geojson-utils";

// Calculate the route length
export function CalcRouteLength(planedRoute) {

  var routeLen = 0

  for (let i = 0; i < planedRoute.length - 1; i++) {
    const lat1 = planedRoute[i].latlng.lat;
    const lng1 = planedRoute[i].latlng.lng;
    const lat2 = planedRoute[i + 1].latlng.lat;
    const lng2 = planedRoute[i + 1].latlng.lng;
    routeLen += gju.pointDistance({type: 'Point', coordinates:[lng1, lat1]},{type: 'Point', coordinates:[lng2, lat2]}) / 1852;
  }

  return routeLen;
}

export default CalcRouteLength;