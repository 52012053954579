import bubblesJson from "./Bubbles.json";
import bubblesPointsJson from "./Bubbles_Points.json";
import airstripsJson from "./Airstrips.json";
import additionalAirstripsJson from "./AdditionalAirstrips.json";
import junctionsJson from "./junctions.json";
import cvfrReportPointsJson from "./CVFR_Report_Points.json";
import lsa_constant_report_pointsJson from "./lsa_constant_report_points.json";
import lsa_negev_report_pointsJson from "./lsa_negev_report_points.json";
import lsa_weekend_report_pointsJson from "./lsa_weekend_report_points.json";
import mapsJson from "./maps.json";

export const bubbles = bubblesJson.features;
export const bubblesPoints = bubblesPointsJson.features;
export const airstrips = airstripsJson.features;
export const additionalAirstrips = additionalAirstripsJson.features;
export const junctions = junctionsJson.features;
export const cvfrReportPoints = cvfrReportPointsJson.features;
export const lsa_constant_report_points = lsa_constant_report_pointsJson.features;
export const lsa_negev_report_points = lsa_negev_report_pointsJson.features;
export const lsa_weekend_report_points = lsa_weekend_report_pointsJson.features;
export const maps = mapsJson.maps;

export * from "./VerifyRoutesData";
