


/***********************************************/


const getGeoCodeWpName = (address) => {

 var wp = "WP"

 if(address.aeroway){
   wp = address.aeroway
 }else if(address.tourism){
   wp = address.tourism
   if(address.city)
     wp += "/" + address.city
 // }else if(address.amenity){
 //   wp = address.amenity
 //   if(address.city)
 //     wp += "/" + address.city
 }else if(address.historic){
   wp = address.historic
   if(address.city)
     wp += "/" + address.city
 }else if(address.village){
   wp = address.village
   if(address.city)
     wp += "/" + address.city
 } else if (address.town){
   wp = address.town
 } else if(address.city){
   wp = address.city
 }
 if(["Area A","Area B","Area C"].includes(address.county)){
   wp += "/" + address.county
 }

 return wp;
}

export async function updateAllGeoData(pointsArray,setMyPlanedRoute){
 var newPointsArray = await Promise.all(pointsArray.map(updateGeoData))
 setMyPlanedRoute(newPointsArray)
}

export async function  updateGeoData(point) {

 var newPoint = point;

 if(point.name === "wp"){

   var name = "WP";

   try {
     if(point.latlng)
     {
       const url= "https://geocode.maps.co/reverse?lat=" + point.latlng.lat +"&lon=" + point.latlng.lng + "&api_key=" + process.env.REACT_APP_GEOCODE_KEY;
       let  geoCodeObj = await fetch(url);
      //  let  geoCodeObj = await fetch(url,{headers : {"Access-Control-Allow-Headers" : "Content-Type",'Accept': 'application/json'}});

       if(geoCodeObj && geoCodeObj.status === 200){
           var geoCode = await geoCodeObj.json()
           if(geoCode.address)
           {
             // console.log('geoCode.address',geoCode.address);
             name = getGeoCodeWpName(geoCode.address)
             
             // console.log('name',name);
           }
       }
     }    
   
   } catch (err) {
       console.log("Geocode fetch exception", err);
   }

   newPoint.name = name;
   newPoint.key =  Math.random() * 1000
   
 }

 return newPoint;
}

