import { getDigits} from ".";


// create the url for the mail message
    export function GetRouteUrl(mapUsed,planedRoute,departureTime){

        const myUrl = window.location.href;
        var routeUrl = myUrl;
        const paramPosition = myUrl.indexOf('?')
        if(paramPosition > 0){
            routeUrl = myUrl.slice(0,paramPosition)
        }

        // routeUrl += mapUsed === "cvfr" ? "?map=cvfr" : "?map=lsa"
        routeUrl +=  "?map=" + mapUsed

        routeUrl += "&dep=" + departureTime.getTime() + "&route="

        // routeUrl += "&route="

        var lastLat = 0
        var lastLng = 0

        for (let i = 0; i < planedRoute.length; i++) {
            
            const lat = planedRoute[i].latlng.lat.toFixed(4);
            const lng = planedRoute[i].latlng.lng.toFixed(4);

            if(i === 0 || lat !== lastLat || lng !== lastLng )
            {
                lastLat = lat
                lastLng = lng

                if(i > 0){
                    routeUrl +='-' 
                }

                routeUrl += lat + ':' + lng;
            }
        }

        
        return encodeURIComponent(routeUrl);
    }

    export function GetHebrewRoute(mapUsed, planedRoute){

        var map = mapUsed === 'lsa'? 'התעופה הספורטיבית' : 'התובלה הנמוכים'


        var hebrewRoute = 'טיסה בנתיבי ' + map + "%0D%0A"  + 
        'המראה מ' + planedRoute[0].name + "%0D%0A" +
        'טיסה דרך: '


        for (let i = 1; i < planedRoute.length - 1; i++) {
            hebrewRoute += planedRoute[i].name 
            if(i !== planedRoute.length - 2){
                hebrewRoute += ' - ' 
            }
        }
        // eslint-disable-next-line no-useless-concat
        hebrewRoute += "%0D%0A" + 'נחיתה ב' + planedRoute[planedRoute.length - 1].name + "%0D%0A"
        return hebrewRoute
    }


        // create the url for the windy app
        export function GetWindyUrl(planedRoute,departureTime){

            
            var windyUrl = "https://www.windy.com/distance/vfr/"

            var lastLat = 0
            var lastLng = 0

            for (let i = 0; i < planedRoute.length; i++) {
                
                const lat = planedRoute[i].latlng.lat.toFixed(2);
                const lng = planedRoute[i].latlng.lng.toFixed(2);
    
                if(i === 0 || lat !== lastLat || lng !== lastLng )
                {
                    lastLat = lat
                    lastLng = lng
    
                    if(i > 0){
                        windyUrl +=';' 
                    }
    
                    windyUrl += lat + ',' + lng;
                }
            }

            windyUrl += '?';

            var departureTimeStr = 
                getDigits(departureTime.getUTCFullYear(),4)  + '-' + 
                getDigits(departureTime.getUTCMonth() + 1,2) + '-' + 
                getDigits(departureTime.getUTCDate(),2)      + '-' + 
                getDigits(departureTime.getUTCHours(),2)
            
            windyUrl += departureTimeStr;

            return windyUrl

        }
    

