import {Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {FlightTime, } from '.'

// 
export function ChangeDepartureTime (props) {

    const { departureTime, updateDepartureTime, timeDialogOpen , setTimeDialogOpen } = props

    const closeDialog = ()=>{ 
      setTimeDialogOpen(false)
    }
 
    return(
    
        <Dialog className="fpl-submit" open={timeDialogOpen} onClose={closeDialog}>
        <DialogTitle  textAlign="center">זמן המראה</DialogTitle>

        <DialogContent dir="ltr">

          <FlightTime departureTime = {departureTime} updateDepartureTime = {updateDepartureTime} />

        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={closeDialog}> OK </Button>
        </DialogActions>
      </Dialog>
    )    
}

export default ChangeDepartureTime;
