import {lsa_weekend_report_points, 
        lsa_negev_report_points,  
        lsa_constant_report_points, 
        cvfrReportPoints, 
        bubbles, 
        airstrips, 
        additionalAirstrips,
        bubblesPoints,
        junctions 
    } from "../data";

/***********************************************/

export function dbPointToRoutePoint(dbPoint){
    return( {
        "latlng": { 
            lat: dbPoint.latlng[0],
            lng: dbPoint.latlng[1]
        },
        "name"  : dbPoint.name,
        "icao"  : dbPoint.icao.slice(0, 5),
        "key"   : Math.random() * 1000
    })
}

/***********************************************/

export function isAirport (icao) 
{
    return (icao.slice(0, 2) === "LL")
}

/***********************************************/

const getRoutePoint = (point) => {

    const [icao,distance] = point.split(":")

    const routePoint = {
        icao: icao,
        distance: parseInt(distance)
    }

    return routePoint

}

/***********************************************/
const isLsa = (mapUsed) => {return (mapUsed === "lsa" || mapUsed === "heli" || mapUsed === "la100" )}
const isCvfr = (mapUsed) => {return (mapUsed === "cvfr" )}

/***********************************************/

const isAirwayOpened = (timeCategory,airwayTime) => {
    
    var isOpened = false;

    if(timeCategory === "AllOpen" ){
        isOpened = true;
    } else if(timeCategory === "WeekDay"){
        if(airwayTime === "00:00"){
            isOpened = true;
        }
    } else if(timeCategory === "OpenOnly6"){
        if(airwayTime === "00:00" || airwayTime === "06:00" ){
            isOpened = true;
        }
    } else if(timeCategory === "OpenOnly12"){
        if(airwayTime === "00:00" || airwayTime === "06:00" || airwayTime === "12:00" ){
            isOpened = true;
        }
    }

    return isOpened
}

/***********************************************/

export function LoadReportPoints(mapUsed,timeCategory){

    var pointsData = []

    if(isCvfr(mapUsed)){
        pointsData = cvfrReportPoints
    } else if (isLsa(mapUsed)){
        if(timeCategory === "WeekDay"){
            pointsData = lsa_constant_report_points;
        } else if (timeCategory === "OnlyWeekendPoints"){
            pointsData = [...lsa_weekend_report_points,...lsa_negev_report_points];
        } else {
            pointsData = [...lsa_weekend_report_points,...lsa_negev_report_points,...lsa_constant_report_points];
        }
    }

    const reportPointsArray = pointsData.map((point) => {

        const {properties, geometry} = point

        const description  = properties.description.split("-");

        const reportPoint = {
            name: properties.name,
            icao: description[0],
            latlng: [geometry.coordinates[1],geometry.coordinates[0]],
            routePoints: [],
            key: properties.name
        }

        for (let i = 1; i < description.length; i++) {
            reportPoint.routePoints.push(getRoutePoint(description[i]))
        }

        return reportPoint
    })

    return reportPointsArray
}

/***********************************************/

export function LoadAirstrips(mapUsed){

    const airstripsArray = []

    airstrips.forEach((point) => {
    
        const {properties, geometry} = point

        const description  = properties.description.split("-");

        const icao = description[0];

        const airstrip = {
            name: properties.name,
            icao: icao,
            direction: description[1],
            latlng: [geometry.coordinates[1],geometry.coordinates[0]],
            routePoints: [],
            key: properties.name
        }

        for (let i = 2; i < description.length; i++) {
            airstrip.routePoints.push(getRoutePoint(description[i]))
        } 

        airstripsArray.push(airstrip)

        // return airstrip
    })

    return airstripsArray
}

/***********************************************/
export function LoadAdditionalAirstrips(mapUsed){

    const airstripsArray = []

    additionalAirstrips.forEach((point) => {
    
        const {properties, geometry} = point

        const description  = properties.description.split("-");

        const icao = description[0];

        const airstrip = {
            name: properties.name,
            icao: icao,
            direction: description[1],
            latlng: [geometry.coordinates[1],geometry.coordinates[0]],
            routePoints: [],
            key: properties.name
        }

        for (let i = 2; i < description.length; i++) {
            airstrip.routePoints.push(getRoutePoint(description[i]))
        } 

        airstripsArray.push(airstrip)

    })

    return airstripsArray
}

/***********************************************/

export function LoadBubblesPoints(mapUsed,timeCategory){

    var bubblesPointsData = []

    if(isLsa(mapUsed)){
        bubblesPointsData = bubblesPoints
    }

    var bubblesArray = []    

    bubblesPointsData.forEach(bubbleData => {

        const {properties, geometry} = bubbleData

        const description  = properties.description.split("-");

        const timeOpened = description[1]

        if(isAirwayOpened(timeCategory,timeOpened) )
        {
            const bubblePoint = {
                name: properties.name,
                icao: description[0],
                latlng: [geometry.coordinates[1],geometry.coordinates[0]],
                routePoints: [],
                key: properties.name
            }
    
            for (let i = 2; i < description.length; i++) {
                bubblePoint.routePoints.push(getRoutePoint(description[i]))
            }
    
            bubblesArray.push(bubblePoint);
        } 
    });

    return bubblesArray
}

/***********************************************/

export function LoadBubbles(mapUsed,timeCategory){

    var bubblesData = []

    if(isLsa(mapUsed)){
        bubblesData = bubbles
    }

    var bubblesArray = []    

    bubblesData.forEach(bubbleData => {

        const {properties, geometry} = bubbleData

        const description  = properties.description.split("-");

        const timeOpened = description[1]

        if(isAirwayOpened(timeCategory,timeOpened) )
        {
            const newBubble = {
                coords: geometry.coordinates[0].map((coord) => {return [coord[1],coord[0]]}),
                key: properties.name,
                title:  properties.name,
                name:  properties.name,
                icao: description[0],
                timeOpened: description[1]
              }
      
              bubblesArray.push(newBubble);
      
        } 
    });

    return bubblesArray
}

/***********************************************/
// https://overpass-turbo.eu/#
// node
//   [ highway=motorway_junction]
//   ({{bbox}});
// out;

export function LoadJunctions(mapUsed){

    const junctionsArray = []

    junctions.forEach((point) => {
    
        const {properties, geometry} = point

        const junction = {
            name: properties.name,
            latlng: [geometry.coordinates[1],geometry.coordinates[0]],
            icao: properties["name:en"] ? properties["name:en"] : "icao",
            key: properties.id
        }

        junctionsArray.push(junction)
    })

    return junctionsArray
}
